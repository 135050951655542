/* eslint-disable react-hooks/exhaustive-deps */
import TopBar from "../../components/TopBar";
import TopContent from "./TopContent";
import UpperContent from "./UpperContent";
import LowerContent from "./LowerContent";
import BottomContent from "./BottomContent";

export default function Home() {
  return (
    <>
      <TopBar />
      <TopContent />
      <UpperContent />
      <LowerContent />
      <BottomContent />
    </>
  );
}
