/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";

export default function Feature({ img, text, handleClick }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "203px",
        height: "65px",
        boxSizing: "border-box",
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "rgba(2, 195, 99, 1)",
      }}
      onClick={handleClick}
    >
      <img
        alt="icon"
        style={{ width: "38px", height: "38px", mr: "8px" }}
        src={img}
      />
      <Box
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "22px",
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
