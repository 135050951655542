import { Box } from "@mui/material";

const Wrap = ({ sx, home, ...other }) => {
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: home ? "100%" : 1152 },
        margin: "0 auto",
        pt: "160px",
      }}
      {...other}
    ></Box>
  );
};

export default function Skeleton({ home, children }) {
  return (
    <Wrap id="wrapper" home={home}>
      {children}
    </Wrap>
  );
}
