import * as React from "react";
import { Box, Typography } from "@mui/material";
import Privilege from "../../components/Privilege";

import hideip from "../../assets/hideip.png";
import faster from "../../assets/faster.png";
import connection from "../../assets/connection.png";
import videos from "../../assets/videos.png";
import vip from "../../assets/vip.png";
import support from "../../assets/support.png";
import ai from "../../assets/ai.png";
import ads from "../../assets/ads.png";

export default function BottomContent() {
  return (
    <Box
      sx={{
        background: "rgba(13, 26, 42, 1)",
        mx: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        pt: "82px",
        pb: "200px",
        mt: "98px",
      }}
    >
      <Box
        sx={{
          fontSize: { xs: "57px", md: "52px" },
          fontWeight: "bold",
          color: "#FFB15B",
        }}
      >
        VIP Privileges
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { xs: "450px", md: "1200px" },
          height: "350px",
          flexWrap: "wrap",
          mt: { xs: "81px", md: "112px" },
        }}
      >
        <Privilege img={hideip} text="Hide IP address" />
        <Privilege img={faster} text="10x faster speed " />
        <Privilege img={ads} text="100% no ads" />
        <Privilege img={vip} text="100+ VIP location" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { xs: "450px", md: "1200px" },
          flexWrap: "wrap",
          mt: { xs: "70px", md: 0 },
        }}
      >
        <Privilege img={videos} text="HD videos anytime" />
        <Privilege img={connection} text="∞+  connection time" />
        <Privilege img={ai} text="Ai channel recommendation" />
        <Privilege img={support} text="24/7 customer support" />
      </Box>
    </Box>
  );
}
