import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import helmet from "../../assets/helmet.png";
import flags from "../../assets/flags.png";

import check from "../../assets/check.png";
import ball from "../../assets/ball.png";
import diamond from "../../assets/diamond.png";

import Focus from "../../components/Focus";

export default function LowerContent() {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "1285px" },
        mx: "auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        mt: { xs: "56px", md: "72px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            fontSize: { xs: "48px", md: "52px" },
            fontWeight: "bold",
            color: "#212121",
          }}
        >
          Our unique advantages
        </Box>
        <Box>
          {isSmallScreen ? (
            <Typography variant="body5">
              Why is CyberProxy better than other VPNs?
            </Typography>
          ) : (
            <Typography variant="body1">
              Why is CyberProxy better than other VPNs?
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: { xs: "center", md: "initial" },
          textAlign: { xs: "center", md: "initial" },
          mt: "64px",
        }}
      >
        <Box
          sx={{
            width: { xs: "656px", md: "530px" },
            height: { xs: "370px", md: "299px" },
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            alt="helmet"
            src={helmet}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "656px", md: "530px" },
            height: { xs: "370px", md: "299px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">Professional gaming acceleration</Typography>
          <Box
            sx={{
              mt: "21px",
              fontSize: { xs: "26px", md: "18px" },
              color: "#4D4D60",
              lineHeight: { xs: "36px", md: "32px" },
            }}
          >
            Comprehensive acceleration for nearly a thousand games worldwide,
            including games like PUBG Mobile, Pokemon GO, Clash Of Clans, Call
            Of Duty: Mobile, Genshin Impact and so on. Our specialized global
            acceleration mode optimizes networks to eliminate latency.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "initial" },
          textAlign: { xs: "center", md: "initial" },
          mt: "64px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "656px", md: "530px" },
            height: { xs: "370px", md: "299px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">Watch blocked videos</Typography>
          <Box
            sx={{
              mt: "21px",
              fontSize: { xs: "26px", md: "18px" },
              color: "#4D4D60",
              lineHeight: { xs: "36px", md: "32px" },
            }}
          >
            Smoothly access to global leading streaming platforms like Netflix,
            Hulu, Disney, HBO, Amazon Prime Video and so on. Never miss any
            visual feast.
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "656px", md: "530px" },
            height: { xs: "370px", md: "299px" },
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            alt="helmet"
            src={flags}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          alignItems: { xs: "center", md: "initial" },
          mt: { xs: 0, md: "135px" },
        }}
      >
        <Focus
          img={check}
          title="Privacy and Security Protection"
          text="Military-grade AES 128-bit encryption protects WiFi hotspots. All information about your IP and online identity will be blocked."
        />
        <Focus
          img={ball}
          title="Global servers, super fast speed"
          text="One-click establishes a fast, stable, and secure connection for you. Watch streaming videos/sports programs/TV shows without buffering. Speed up network speed when playing games. Enjoy a super-fast online browsing experience."
        />
        <Focus
          img={diamond}
          title="Easy-to-use and expertly designed"
          text="Unlimited free VPN service. Enjoy free and fast servers anytime, anywhere. Smart selection of the best server. User-friendly interface. Compatible with WiFi, LTE, 3G, and all mobile data carriers."
        />
      </Box>
    </Box>
  );
}
