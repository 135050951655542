import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Ashley from "../../assets/touxiang/ashely.jpeg";
import ben from "../../assets/touxiang/ben.jpeg";
import david from "../../assets/touxiang/david.jpeg";
import jeremy from "../../assets/touxiang/jeremy.jpeg";
import johnathan from "../../assets/touxiang/johnathan.jpeg";
import leah from "../../assets/touxiang/leah.jpeg";
import kevin from "../../assets/touxiang/kevin.jpeg";
import lucy from "../../assets/touxiang/lucy.jpeg";

import Comment from "../../components/Comment";
export default function UpperContent() {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        pt: "50px",
        backgroundColor: "rgba(248, 248, 248, 0.57)",
        pb: "96px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            fontSize: { xs: "48px", md: "52px" },
            fontWeight: "bold",
            color: "#212121",
          }}
        >
          Listen to other users' thoughts
        </Box>
        <Box>
          {isSmallScreen ? (
            <Typography variant="body5">
              See how other online users rate SailfishVPN.
            </Typography>
          ) : (
            <Typography variant="body1">
              See how other online users rate SailfishVPN.
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        className="contaner"
        sx={{
          mt: "50px",
          width: '100%',
          overflow: "scroll",
        }}
      >
        <Box
          className="scroller"
          sx={{
            display: "flex",
            width: { xs: "3400px", md: "3560px" },
          }}
        >
          <Comment
            img={Ashley}
            name="Ashley R. Hoover"
            score="5.0"
            comment="As an excellent VPN service, CyberProxy has received wide acclaim in the industry. Both my colleagues and I are using it on our Android phones."
          />
          <Comment
            img={ben}
            name="Ben Cable"
            score="5.0"
            comment="I highly recommend using CyberProxy. With its dedicated gaming acceleration route, it can help you enjoy games smoothly and achieve better results. You deserve to have it!"
          />
          <Comment
            img={david}
            name="David Addati"
            score="4.9"
            comment="CyberProxy provides military-grade AES 128-bit encryption to protect users' WiFi hotspots and online identity. If you use SailfishVPN when browsing online, you don't have to worry about security issues or information leakage."
          />
          <Comment
            img={jeremy}
            name="Jeremy Wershoven"
            score="5.0"
            comment="I've tried numerous VPNs over the years, but none have impressed me as much as this one. From the moment I signed up, it was clear that I had stumbled upon something truly exceptional. "
          />
          <Comment
            img={johnathan}
            name="Johnathan Kent"
            score="5.0"
            comment="I highly recommend using CyberProxy. With its dedicated gaming acceleration route, it can help you enjoy games smoothly and achieve better results. You deserve to have it!"
          />
          <Comment
            img={kevin}
            name="Kevin James"
            score="5.0"
            comment="Superbly convenient and the service is exceptionally attentive. Whenever I have a question, the customer support promptly and professionally assists me in resolving any issues. The prices are incredibly affordable! "
          />
          <Comment
            img={leah}
            name="Leah Saba"
            score="5.0"
            comment="To be honest, this is the best VPN I've come across, regardless of price, customer service, or the software interface."
          />
          <Comment
            img={lucy}
            name="Lucy Schofield"
            score="5.0"
            comment="I can confidently say that this VPN has exceeded my expectations in every way. Its unmatched speed, reliable performance, exceptional customer service, and affordable pricing make it the absolute best choice for anyone seeking a top-tier VPN experience. "
            // comment="I can confidently say that this VPN has exceeded my expectations in every way. Its unmatched speed, reliable performance, exceptional customer service, and affordable pricing make it the absolute best choice for anyone seeking a top-tier VPN experience. I wholeheartedly recommend it to everyone in need of a reliable and feature-rich VPN solution."
          />
        </Box>
      </Box>
    </Box>
  );
}
