import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import App from "./App";
import ThemeProvider from "./Theme";
import { JollyMaxProvider } from "./providers/JollyMaxProvider";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider>
      <JollyMaxProvider>
        <App />
      </JollyMaxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
