import { createContext, useContext, useState } from "react";
export const JollyMaxContext = createContext({
  status: "on",
  toggleStatus: () => null,
});
export const JollyMaxProvider = ({ children }) => {
  const status_ = window.sessionStorage.getItem("status") || "on";
  const [status, setStatus] = useState(status_);
  const toggleStatus = () => {
    window.sessionStorage.setItem("status", "off");
    setStatus("off");
  };
  return (
    <JollyMaxContext.Provider value={{ status, toggleStatus }}>
      {children}
    </JollyMaxContext.Provider>
  );
};

export const useJollyMaxContext = () => useContext(JollyMaxContext);
