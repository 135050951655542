import { useMediaQuery, Box, Typography } from "@mui/material";
import jollymax from "../assets/jollymax.png";
import gift from "../assets/gift.png";
import close from "../assets/close.png";
import { useJollyMaxContext } from "../providers/JollyMaxProvider";
import { goToAddress } from "../utils";

export default function PriceInput({
  value,
  max,
  onChange = () => {},
  sx,
  ...other
}) {
  const { status, toggleStatus } = useJollyMaxContext();
  console.log(status, "status");
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box
      sx={{
        display: status === "on" ? "flex" : "none",
        justifyContent: { xs: "center" },
        alignItems: "center",
        position: { xs: "relative", md: "relative" },
        // bottom: { xs: "48px", md: 0 },
        left: { xs: "0" },
        background: "linear-gradient(90deg, #143459 0%, #3D6089 100%);",
        height: { xs: "120px", md: "80px" },
        width: { xs: "100%" },
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "100%", md: "1400px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          alt="gitf"
          style={{ height: "72px", width: "72px", marginRight: "14px" }}
          src={gift}
        />
        <Box
          className="jollymax-text"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isSmallScreen ? (
            <Typography
              style={{ display: "block", width: "440px" }}
              variant="h5"
            >
              Complete any recharge order on JollyMax over $20 + Get one month
              of VIP trial
            </Typography>
          ) : (
            <>
              <Typography variant="h5">
                Complete any recharge order on
              </Typography>
              <img
                style={{ width: "111px", height: "27px", margin: "0 8px" }}
                alt="jollymax"
                src={jollymax}
              />
              <Typography variant="h5">
                over $20 + Get one month of VIP trial time for SailfishVPN
              </Typography>
            </>
          )}

          <Box
            sx={{
              width: { xs: "112px", md: "132px" },
              height: "48px",
              background: "linear-gradient(90deg, #F8DEAF 0%, #FFC67D 100%)",
              borderRadius: "27px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "28px",
              color: "#60361D",
              cursor: "pointer",
              marginLeft: "10px",
              boxSizing: "border-box",
            }}
            onClick={() => {
              goToAddress(
                "https://www.jollymax.com/market/jollybeanshop?from=shi_vpn_20230523_event_jollybeanshop_banner_0&cache=true&titlebar=hide&screen=vertical&hideHeader=1&hideFooter=1"
              );
            }}
          >
            GET
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: { xs: "40px" },
          width: { xs: "40px" },
          cursor: "pointer",
          position: "absolute",
          right: { xs: "4px", md: "10px" },
          top: { xs: "4px", md: "initial" },
        }}
        onClick={() => {
          toggleStatus();
        }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          alt="close"
          src={close}
        />
      </Box>
    </Box>
  );
}
