/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";

export default function Feature({ img, text }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "40px",
        mt: "14px",
        whiteSpace: "nowrap",
        width: { xs: "680px", md: "initial" },
      }}
    >
      <img
        alt="icon"
        style={{ width: "40px", height: "42px", mr: "15px" }}
        src={img}
      />
      <Box
        sx={{
          fontSize: { xs: "26px", md: "20px" },
          color: "#4D4D60",
          lineHeight: "42px",
          width: { xs: "650px", md: "initial" },
          ml: "15px",
          wordWrap: { xs: "break-word", md: "initial" },
          wordBreak: { xs: "break-all", md: "initial" },
          whiteSpace: { xs: "pre-line", md: "initial" },
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
