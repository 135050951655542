import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  mode: "dark",
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#515874",
    },
    darker: {
      main: "rgba(64, 111, 144, 0.66)",
    },
    text: {
      primary: "#000",
    },
    action: {
      disabledBackground: "rgba(255, 255, 255, 0.2)",
      disabled: "rgba(255, 255, 255, 0.8)",
    },
  },
  typography: {
    fontFamily: "Source Sans Pro",
    body1: {
      fontSize: "20px",
      color: "#4D4D60",
      lineHeight: "42px",
    },
    body2: {
      fontSize: "24px",
      color: "#01021D",
    },
    body3: {
      fontSize: "18px",
      color: "#4D4D60",
    },
    body4: {
      color: "#F2C38B",
      fontSize: "24px",
      lineHeight: "28px",
      textAlign: "center",
    },
    body5: {
      fontSize: "26px",
      color: "#4D4D60",
      lineHeight: "42px",
      wordWrap: "break-word",
      wordBreak: "break-all",
    },
    allVariants: {
      color: "#fff",
    },
    h1: {
      fontSize: "60px",
      fontWeight: 900,
      color: "#000000",
      lineHeight: "80px",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "bold",
      color: "#000000",
      lineHeight: "57px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "40px",
      color: "#01021D",
    },
    tip: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#eabd4e",
      textShadow: "initial",
    },
    menu: {
      fontSize: 16,
      color: "#406f90",
      fontWeight: "bold",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          // borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          // borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          display: "none",
        },
        root: {
          border: "1px solid #fff",
          lineHeight: "38px",
          maxHeight: "38px",
          "& $notchedOutline": {
            borderColor: "green",
            // display: "none",
          },
          "&:hover $notchedOutline": {
            borderColor: "red",
          },
          "&$focused $notchedOutline": {
            borderColor: "purple",
          },
          "&:hover": {},
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          // borderRadius: 0,
          fontWeight: 900,
          fontSize: "1rem",
          // padding: "10px",
          minWidth: 120,
          border: "1px solid #fff",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "#fff",
          backgroundColor: "#2e8b90",
        },
        containedPrimary: {
          color: "#000",
        },
      },
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
