/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";

export default function Privilege({ img, text }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "200px",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "160px", md: "200px" },
          height: { xs: "160px", md: "200px" },
        }}
      >
        <img alt="icon" style={{ width: "100%", height: "100%" }} src={img} />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          fontSize: { xs: "26px", md: "24px" },
          lineHeight: { xs: "28px", md: "28px" },
          color: "#F2C38B",
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
