/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";

export default function Focus({ img, title, text }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: { xs: "656px", md: "332px" },
        alignItems: "center",
        textAlign: "center",
        mt: { xs: "66px", md: 0 },
      }}
    >
      <Box
        sx={{
          width: { xs: "96px", md: "92px" },
          height: { xs: "96px", md: "92px" },
        }}
      >
        <img alt="icon" style={{ width: "100%", height: "100%" }} src={img} />
      </Box>
      <Box
        sx={{
          mt: "20px",
          fontSize: { xs: "32px", md: "24px" },
          color: "#01021D",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          marginTop: "10px",
          fontSize: { xs: "26px", md: "18px" },
          color: "#4D4D60",
          fontWeight: { xs: 400, md: "initial" },
        }}
      >
        {text}
      </Box>
    </Box>
  );
}
