/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import star from "../assets/star.png";

export default function Feature({ img, name, score, comment }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: { xs: "start", md: "center" },
        width: { xs: "391px", md: "420px" },
        height: { xs: "597px", md: "440px" },
        boxSizing: "border-box",
        borderRadius: "24px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        px: "24px",
        pt: "32px",
        mx: "12px",
      }}
    >
      <Box
        sx={{
          width: { xs: "72px", md: "96px" },
          height: { xs: "72px", md: "96px" },
        }}
      >
        <img
          alt="icon"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            display: "block",
          }}
          src={img}
        />
      </Box>
      <Box
        sx={{
          fontSize: { xs: "28px", md: "24px" },
          fontWeight: 400,
          color: "#01021d",
          mt: "13px",
        }}
      >
        {name}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "13px",
        }}
      >
        <Box sx={{ fontSize: { xs: "24px", md: "22px" } }}>{score}</Box>
        <Box>
          <img alt="star" src={star} />
          <img alt="star" src={star} />
          <img alt="star" src={star} />
          <img alt="star" src={star} />
          <img alt="star" src={star} />
        </Box>
      </Box>
      <Box
        sx={{
          fontSize: { xs: "26px", md: "18px" },
          color: "#4D4D60",
          lineHeight: { xs: "36px", md: "32px" },
          textAlign: { xs: "left", md: "center" },
          mt: { xs: "22px", md: "23" },
        }}
      >
        {comment}
      </Box>
    </Box>
  );
}
