import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";

import Skeleton from "./views";
import LoadingScreen from "./components/LoadingScreen";
import HomePage from "./views/home/index";
// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks

//   return (
//     <Suspense fallback={<LoadingScreen />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <Skeleton home={true}>
          <HomePage />
        </Skeleton>
      ),
    },
    {
      path: "/*",
      element: <HomePage />,
    },
  ]);
}
// const HomePage = Loadable(lazy(() => import("./views/home/index")));
